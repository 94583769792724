define('ember-simple-leaflet-maps/services/geocode', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),

    async fetchCoordinates(query) {
      let encodedQuery = encodeURIComponent(query);
      let accessToken = Ember.getOwner(this).resolveRegistration('config:environment')['ember-simple-leaflet-maps'].apiKey;
      let response = await this.ajax.request(`/geocoding/v5/mapbox.places/${encodedQuery}.json?access_token=${accessToken}`);
      if (response.features.length > 0) {
        let feature = response.features[0];
        return feature;
      }
      return [];
    }
  });
});